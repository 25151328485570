body {
  padding: 0;
  margin: 0;
}

input:-webkit-autofill {
  background-color: transparent !important; /* Change this to your desired background color */
  -webkit-box-shadow: 0 0 0px 1000px #000000 inset !important;
  -webkit-text-fill-color: #ffffff !important;
  color: #ffffff !important; /* Ensure text color is appropriate */
}

/* For Firefox */
input:-moz-autofill {
  background-color: transparent !important; /* Change this to your desired background color */
  -moz-text-fill-color: #ffffff !important;
  color: #ffffff !important; /* Ensure text color is appropriate */
}

/* For other browsers that support autofill */
input:autofill {
  background-color: transparent !important; /* Change this to your desired background color */
  text-fill-color: #000 !important;
  color: #ffffff !important; /* Ensure text color is appropriate */
}

/* For WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #232323; /* Dark gray track color */
}

::-webkit-scrollbar-thumb {
  background-color: #ffc800; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #232323; /* Optional: adds a border around the thumb */
}

/* For Firefox */
html {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #ffc800 #232323; /* Thumb color and track color */
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(75vh - 80px) !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader-inner-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loader-text {
  margin-right: 2%;
  font-weight: 500;
  font-size: 1.2rem;
}

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1.5);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.5);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

select::-ms-expand {
  display: block;
}

/* .table-cell{
font-size: 5px;
} */
h4 {
  font-size: 20px !important;
}

h6 {
  font-size: 14px !important;
}

.css-1qi8750-MuiButtonBase-root-MuiTab-root {
  color: #ffffff;
  border-bottom: 1px solid #2c2c2c;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  color: #ffc800;
}

.css-j11maj-MuiTableCell-root {
  border-bottom: 1px solid rgb(41 42 42);
}

.css-xnfavz-MuiTableCell-root {
  border-bottom: 1px solid rgb(41 42 42);
}

.css-1qi8750-MuiButtonBase-root-MuiTab-root.Mui-selected {
  /*   color: #FFC800; */
  color: #ffffff;
  font-size: 13px;
}

.accordionCustom {
  display: inline;
  align-items: center;
  justify-content: center;
  text-align: left;
}

/* below css is for date picker filter in the table  */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-y6d1ek-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  margin: 0px 0 0 0;
}
.css-1d4ae4p-MuiTableCell-root {
  background-color: #1c1c1c !important;
}

/* below css for transaction table */
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall.css-1achpeu-MuiTableCell-root {
  border-bottom: 1px solid #333333;
  font-size: 13px;
}

tr.MuiTableRow-root.MuiTableRow-head.css-egi9vb-MuiTableRow-root {
  border-bottom: 1px solid #333333;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-5sq6t1-MuiTableCell-root {
  padding: 0px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  padding: 8px;
  font-size: "0.8rem";
  font-weight: 400;
}

tr:last-child td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
  border: 0;
}

tbody tr.MuiTableRow-root:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bu5qrz-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-zbpb99-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  height: 31px;
  padding: 10px 0 0 10px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.css-1gfnvno-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  text-align: left;
  padding: 10px;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.css-1br9cjw-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: center;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.css-1yjllt9-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: center;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.css-17ngcqk-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: center;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1aogqpz-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 10px;
  text-align: left;
  font-size: 13px;
}

.MuiBox-root.css-70qvj9 {
  text-align: left;
  /*   justify-content: center; */
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.css-16p5dbi-MuiTableCell-root {
  text-align: left;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.banklist.css-1gfnvno-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: center;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.banklist.css-1br9cjw-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: center;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.banklist.css-17ngcqk-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: center;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.banklist.css-1yjllt9-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: center;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.banklist.css-16p5dbi-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 0px;
  text-align: left;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.banklist.css-1gfnvno-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  text-align: center;
  font-size: 13px;
}

p.MuiTypography-root.MuiTypography-body1.css-h1hycd-MuiTypography-root {
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.banklist.css-a17yc7-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
}

p.MuiTypography-root.MuiTypography-body1.css-19wnp4i-MuiTypography-root {
  text-align: left;
  font-size: 13px;
  align-items: center;
  display: flex;
}

p.MuiTypography-root.MuiTypography-body1.recipient_name.css-1kbucyf-MuiTypography-root {
  text-align: left;
  font-size: 13px;
  align-items: center;
  display: flex;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.B_add.css-1aogqpz-MuiTableCell-root {
  text-align: left;
  font-size: 13px;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.redeemR.css-1aogqpz-MuiTableCell-root {
  text-align: left;
  padding: 10px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.redeemR.css-1gfnvno-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.transaction.status.css-1br9cjw-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
  text-align: center;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.transaction.status.css-16p5dbi-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
  text-align: center;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.transaction.status.css-17ngcqk-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
  text-align: center;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.transaction.status.css-1yjllt9-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
  text-align: center;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.redeemR.css-1br9cjw-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.redeemR.css-17ngcqk-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.redeemR.css-17ngcqk-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.redeemR.css-1yjllt9-MuiTableCell-root {
  border-bottom: 1px solid #5c5858;
  padding: 5px;
  font-size: 13px;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.selltable.css-1aogqpz-MuiTableCell-root {
  text-align: left;
  padding-bottom: 10px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.sell.css-1gfnvno-MuiTableCell-root {
  text-align: left;
  padding: 0%;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.sell.css-1br9cjw-MuiTableCell-root {
  text-align: left;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.sell.css-1yjllt9-MuiTableCell-root {
  text-align: left;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.bordr.sell.css-17ngcqk-MuiTableCell-root {
  text-align: left;
}

p.MuiTypography-root.MuiTypography-body1.seller.css-h1hycd-MuiTypography-root {
  font-size: 13px;
}

span.redeemId {
  color: rgb(145 143 143);
  font-size: 12px;
}

p.MuiTypography-root.MuiTypography-body1.trans.css-aypvvf-MuiTypography-root {
  font-size: 13px;
}

input#redeem-code {
  height: 3px;
}

span.Trans-Id {
  font-size: 13px;
  color: #dbdbdb;
}

.trans-success {
  font-weight: 500;
  display: flex;
  align-items: center;
}

span.transType {
  padding-left: 10px;
  font-size: 13px;
}
.btn-help {
  color: #ffffff;
  font-size: 12px !important;
  padding: 0px;
}
.trans-status {
  font-size: 14px;
}
span.transType.qty {
  font-size: 20px;
  display: grid;
}
span.transAEG {
  font-size: 14px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.bank-details.css-jwkm6s-MuiPaper-root {
  text-align: left;
}
h6.MuiTypography-root.MuiTypography-subtitle1.bank-details-title.css-lv7sze-MuiTypography-root {
  font-size: 12px !important;
  color: rgb(206 206 206);
  font-weight: 400;
}
p.MuiTypography-root.MuiTypography-body1.bank-details-description.css-aypvvf-MuiTypography-root {
  font-size: 13px;
  color: white;
  font-weight: 500;
}
.edit-bank-detail {
  color: #959090;
  border: 1px solid #534f4f;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  font-weight: 500;
}
.bank-details-subtitle {
  font-size: 12px;
  color: #b6b6b6;
}
p.MuiTypography-root.MuiTypography-body1.dateCalendar.css-aypvvf-MuiTypography-root {
  background-color: #1f1f1f;
  border-radius: 50px;
  width: 38px;
  height: 38px;
  padding: 7px;
}
p.MuiTypography-root.MuiTypography-body1.reedem.user.css-xlvnan-MuiTypography-root {
  font-size: 13px;
}
p.MuiTypography-root.MuiTypography-body1.reedem.user.css-aypvvf-MuiTypography-root {
  font-size: 13px;
}
p.MuiTypography-root.MuiTypography-body1.reedem.user.css-1pan5a4-MuiTypography-root {
  font-size: 13px;
}
p.MuiTypography-root.MuiTypography-body1.reedem.user.css-auk041-MuiTypography-root {
  font-size: 13px;
}
p.MuiTypography-root.MuiTypography-body1.reedem.user.css-1p3pjam-MuiTypography-root {
  font-size: 13px;
}
